import React, { useRef } from 'react';
import QRCode from 'react-qr-code';
import * as htmlToImage from 'html-to-image';

function QRCodeLink({ showDownloadButton }) {
  const qrCodeRef = useRef(null);

  const downloadQRCode = () => {
    const qrCodeSvg = qrCodeRef.current.querySelector('svg');

    htmlToImage
      .toPng(qrCodeSvg)
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = 'QR-VinhNhat-Wedding.png';
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error('Failed to download QR Code:', error);
      });
  };

  return (
    <div className="col-md-2 col-sm-2">
      <div className="qr-code">
        <div className="qr__wrapper--code" style={{ backgroundColor: 'transparent' }} id="qrcode" ref={qrCodeRef}>
          <QRCode value={`https://vinhnhatwedding.com/`} size={150} bgColor="transparent" />
        </div>
      </div>
      {showDownloadButton && (
        <div className="download">
          <button onClick={downloadQRCode} className="download-button">
            Download QR Code
          </button>
        </div>
      )}
    </div>
  );
}

export default React.memo(QRCodeLink);
