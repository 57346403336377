import { css } from '@emotion/core';

export const styWrapper = css`
  width: 100%;
  font-family: 'Time New Roman';
  .qr-layout {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .qr-code {
    border: 1px solid;
    margin: 8px 0;
    padding: 16px 16px 16px 16px;
  }
  .download {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .download-button {
    background-color: #00b2ee;
    color: white;
    border: none;
  }
  .qr__wrapper--code {
    display: flex;
    justify-content: center;
  }

  .title__name {
    text-align: center;
    margin-top: 8px;
    margin: 8px 0 0 0;
    font-size: 11px;
  }

  .title__code {
    text-align: center;
    font-size: 10px;
    margin: 0 0 8px 0;
  }

  .title__transaparent {
    opacity: 0;
  }
`;
