import React from 'react';

import MainLayout from '@components/Layout';
import QRCodeLink from '@components/QRCodeLink';
import { styWrapper } from '@components/QRCodeLink/styles';

function GenerateQRPageLink() {
  return (
    <MainLayout>
      <div css={styWrapper}>
        <div className="qr-layout">{<QRCodeLink showDownloadButton={true} />}</div>
      </div>
    </MainLayout>
  );
}

export default GenerateQRPageLink;
